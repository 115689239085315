
import { mapGetters } from "vuex"
import { debounce, uniqBy } from "lodash"

import SideSheet from "~/components/SideSheet.vue"
import ChatItem from "~/components/Chat/ChatItem.vue"
import ChatTyping from "~/components/Chat/ChatTyping.vue"
import ChatGroupAvatar from "~/components/Chat/ChatGroupAvatar.vue"
import ChatInput from "~/components/Chat/ChatInput.vue"
import ModalCreateGroup from "~/components/Chat/ModalCreateGroup.vue"

export default {
  name: "ChatPanel",
  components: {
    SideSheet,
    ChatItem,
    ChatTyping,
    ChatGroupAvatar,
    ChatInput,
    ModalCreateGroup,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    avatar: {
      type: [String, Array],
      default: require("~/assets/images/svgs/practitioner-avatar.svg"),
    },
    name: {
      type: String,
      default: "",
    },
    actives: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    hasNewMessages: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 0,
    },
    lastPage: {
      type: Number,
      default: 0,
    },
    typing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      infiniteId: +new Date(),
      payload: "",
      file: null,
      selfTyping: false,
      scroll: {
        bottom: 0,
        current: 0,
      },
      editGroup: {
        visible: false,
      },
      sent: 0,
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    minimized() {
      return !this.actives.includes(Number(this.id))
    },
    parsedMessages() {
      const parsed = {}
      const messages = uniqBy(this.messages, "id")

      messages.forEach((message) => {
        const date = this.$dayjs(message.created_at).startOf("day")

        if (!parsed[date]) {
          parsed[date] = []
        }

        if (message.file && typeof message.file === "string") {
          message.file = JSON.parse(message.file)
        }

        parsed[date].push(message)
      })

      return parsed
    },
  },
  watch: {
    payload: {
      handler: debounce(function (payload) {
        if (this.type === "group") return
        if (payload && payload.length) {
          if (this.selfTyping) return

          this.selfTyping = true
          this.$api.IS_TYPING.postModel({
            typing: 1,
            to_user_id: this.id,
          })
        } else {
          this.selfTyping = false
          this.$api.IS_TYPING.postModel({
            typing: 0,
            to_user_id: this.id,
          })
        }
      }, 200),
    },
    minimized: {
      handler(minimized) {
        if (!minimized && this.hasNewMessages) {
          this.push({ hasNewMessages: false })
        }
      },
    },
    hasNewMessages: {
      handler(hasNewMessages) {
        if (!hasNewMessages) return
        try {
          const audio = new Audio("/sounds/message.mp3")
          audio.play()
        } catch {}
      },
      immediate: true,
    },
    parsedMessages: {
      handler() {
        setTimeout(this.handleChangeConvo, 100)
      },
    },
    typing: {
      handler(typing) {
        if (!typing) return
        this.handleChangeConvo()
      },
    },
    name: {
      handler() {
        if (this.type !== "group" || this.messages.length) return
        this.infiniteId++
      },
    },
  },
  mounted() {
    const chatBody = this.$refs["chat-body"]
    chatBody.addEventListener("scroll", this.handleScroll)

    chatBody.scrollTop = chatBody.scrollHeight - chatBody.clientHeight
  },
  beforeDestroy() {
    const chatBody = this.$refs["chat-body"]
    chatBody.removeEventListener("scroll", this.handleScroll)
    if (this.selfTyping) {
      this.$api.IS_TYPING.postModel({
        typing: 0,
        to_user_id: this.id,
      })
    }
  },
  methods: {
    fetchMessages() {
      if (this.type === "group") {
        return this.$api.CONVERSATIONS.getWithParams(
          `${this.id - 100000}?page=${this.page}`
        )
      } else {
        this.$api.SET_READ_MESSAGE.postModel({
          to_user_id: this.getUser.id,
          from_user_id: this.id,
        })
        return this.$api.MESSAGE_LATEST.postWithQS(`page=${this.page}`, {
          to_user_id: this.id,
        })
      }
    },
    infiniteScroll($infi) {
      this.push({ page: this.page + 1 })
      this.push({ loading: true })

      this.fetchMessages()
        .then(({ data: { data, last_page } }) => {
          if (!data.length) return $infi.complete()

          this.push({
            messages: [...data.reverse(), ...this.messages],
            lastPage: last_page,
          })

          $infi.loaded()
        })
        .catch(() => {
          $infi.complete()
        })
        .finally(() => {
          this.push({ loading: false })
        })
    },
    toggle() {
      if (this.minimized) {
        this.$emit("PUSH_ACTIVES", this.id)
      } else {
        this.$emit("POP_ACTIVES", this.id)
      }
    },
    close() {
      this.$emit("close", this.id)
    },
    send() {
      if (!this.payload) return
      this.sent = Date.now()
      this.$emit("sendMessage", {
        field: this.id,
        payload: this.payload,
        from_user_id: this.getUser.id,
        file: this.file,
        type: this.type ?? null,
      })

      this.handleChangeConvo(true)

      this.payload = ""
      this.handleRemoveFile()
    },
    handleScroll({ target }) {
      this.scroll.bottom = target.scrollHeight - target.clientHeight
      this.scroll.current = target.scrollTop
    },
    handleChangeConvo(isSelf = false) {
      this.$forceUpdate()

      setTimeout(() => {
        const chatBody = this.$refs["chat-body"]
        this.scroll.bottom = chatBody.scrollHeight - chatBody.clientHeight

        const { bottom, current } = this.scroll
        if (isSelf) return (chatBody.scrollTop = bottom)
        const percentage = (current / bottom) * 100

        if (percentage > 50 || bottom < 300) {
          return (chatBody.scrollTop = bottom)
        }
      }, 0)
    },
    push(payload) {
      this.$emit("pushMessages", {
        field: this.id,
        payload,
      })
    },
    attachment(event) {
      if (this.loading) return
      const files = event.target.files
      if (!files.length) return
      this.file = files[0]
    },
    handleRemoveFile() {
      this.file = null
      this.$refs.attachment.value = ""
    },
  },
}
