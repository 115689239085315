import { render, staticRenderFns } from "./ModalSendMessage.vue?vue&type=template&id=715e5c2a&scoped=true&"
import script from "./ModalSendMessage.vue?vue&type=script&lang=js&"
export * from "./ModalSendMessage.vue?vue&type=script&lang=js&"
import style0 from "./ModalSendMessage.vue?vue&type=style&index=0&id=715e5c2a&prod&lang=scss&scoped=true&"
import style1 from "./ModalSendMessage.vue?vue&type=style&index=1&id=715e5c2a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "715e5c2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatAvatar: require('/home/ubuntu/coherent-frontend/components/Chat/ChatAvatar.vue').default})
