export default {
  SET_LIST(state, payload) {
    state.list = payload
  },
  PUSH_LIST(state, payload) {
    state.list.push(payload)
  },
  REMOVE_LIST(state, payload) {
    state.list = state.list.filter((item) => item.id !== payload.id)
  },
  UPDATE_LIST(state, payload) {
    state.list = state.list.map((item) => {
      if (item.id === payload.id) {
        return payload
      }
      return item
    })
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}
