
// vuex
import { mapGetters } from "vuex"

// components
import ChatAvatar from "~/components/Chat/ChatAvatar.vue"

// jsons
import menuListJSON from "~/jsons/menu.json"

export default {
  name: "AppHeader",
  components: {
    ChatAvatar,
  },
  props: {
    showmenu : {
      default: true
    }
  },
  data() {
    return {
      menuList: [],
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "getUser"]),
    user() {
      return this.getUser
    },
  },
  mounted() {
    const user = this.user
    let menuList = []

    if (user && user.is_admin === 1) {
      menuList = menuListJSON.filter(
        (x) => x.location === "header" || x.location === "both"
      )
    } else {
      menuList = menuListJSON.filter(
        (x) =>
          (x.location === "header" || x.location === "both") &&
          x.role !== "admin"
      )
    }

    this.menuList = menuList

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty("--app-height", `${window.innerHeight}px`)
    }

    window.addEventListener("resize", appHeight)
    appHeight()
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout", true)
    },
    handleSidebar() {
      this.$store.commit("SET_SIDEBAR", true)
    },
    redirectToProfile() {
      window.location = "/user/profile"
    },
    redirectToSettings() {
      window.location = "/user/settings"
    }
  },
}
