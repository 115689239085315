
import { mapGetters } from "vuex"
import { orderBy } from "lodash"

import ChatAvatar from "~/components/Chat/ChatAvatar.vue"
import ChatGroupAvatar from "~/components/Chat/ChatGroupAvatar.vue"
import createConvo from "~/helpers/createConvo"

export default {
  name: "ChatConnections",
  components: {
    ChatAvatar,
    ChatGroupAvatar,
  },
  props: {
    newMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      onlineUserWithPic: [],
      onlineUserWithoutPic: [],
      offlineUser: []
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser", "isAuthenticated"]),
    ...mapGetters(["lastChat", "groupChats"]),
    connections() {
      /* eslint-disable */
      this.onlineUserWithPic = []
      this.onlineUserWithoutPic = []
      this.offlineUser = []
      /* eslint-enable */
      const sorted = []
      const unread = []
      if (!this.isAuthenticated || !this.getUser || !this.getUser.connections) {
        return sorted
      }
      const connections = [...this.groupChats, ...this.getUser.connections]
      let unread_message_connections = []

      if(this.getUser.unread_message_connections !== undefined) {
        unread_message_connections = [
          ...this.getUser.unread_message_connections,
        ]
        unread_message_connections.forEach((x) => {
          unread.push(x.initiating_user_id)
        })
      }
      const sortedByLastMessage = orderBy(
        connections,
        ({ last_message_datetime }) => last_message_datetime || "",
        ["desc"]
      )
      // const sortedByOnlineStatus = sortedByLastMessage.sort((a, b) => {
      //   const aOnline = this.isConnectionOnline(a)
      //   const bOnline = this.isConnectionOnline(b)

      //   if (aOnline && !bOnline) return -1
      //   if (!aOnline && bOnline) return 1

      //   return 0
      // })
      // sortedByOnlineStatus.forEach((x) => {
      //   if (unread.includes(x.receiving_user_id)) {
      //     x.newmsg = true
      //     sorted.push(x)
      //   } else if (x.user && x.user.avatar !== null && x.user.status === "online") {
      //     x.newmsg = false
      //     this.onlineUserWithPic.push(x)
      //   } else if (x.user && x.user.avatar === null && x.user.status === "online") {
      //     x.newmsg = false
      //     this.onlineUserWithoutPic.push(x)
      //   } else {
      //     x.newmsg = false
      //     this.offlineUser.push(x)
      //   }
      // })
      // SORT ONLY RECENT CHAT
      sortedByLastMessage.forEach((x) => {
        if (unread.includes(x.receiving_user_id)) {
          x.newmsg = true
          sorted.push(x)
        } else{
          x.newmsg = false
          sorted.push(x)
        }
      })
      return sorted
      // return sorted.concat(
      //   this.onlineUserWithPic,
      //   this.onlineUserWithoutPic,
      //   this.offlineUser
      // )
    },
  },
  mounted() {
    this.$store.dispatch("fetchGroupChats")
  },
  methods: {
    handleClickItem(item) {
      setTimeout(() => {
        document
          .getElementById("item" + item.user.id)
          .classList.remove("has-border")
      }, 500)
      this.$emit("setConvos", createConvo(item))
      this.$emit("update")
      // call api for read message here
      this.$api.SET_READ_MESSAGE.postModel({
        to_user_id: item.initiating_user_id,
        from_user_id: item.receiving_user_id,
      })
      .then((res) => {
          setTimeout(() => {
            document
              .getElementById("item" + item.user.id)
              .classList.remove("has-border")
          }, 500)
      })
    },
    isConnectionOnline(connection) {
      if (!connection.user) return false
      const found = this.getUser.connections.find((x) => {
        return Number(x.user.id) === Number(connection.user.id)
      })
      if (found) return found.user.status === "online"
      return false
    },
  },
}
