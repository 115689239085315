// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

import Vue from "vue"
import { BCollapse, BSpinner, BButton, BPopover } from "bootstrap-vue"

Vue.component("BCollapse", BCollapse)
Vue.component("BButton", BButton)
Vue.component("BSpinner", BSpinner)
Vue.component("BPopover", BPopover)
