export default {
  async fetchList({ getters, commit }) {
    if (getters.loading) return

    commit("SET_LOADING", true)

    await this.app.$api.USER_ACTIVITIES.get()
      .then(({ data }) => {
        commit("SET_LIST", data)
      })
      .catch((err) => {
        this.app.$toast.error(err.message)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  async createActivity({ commit }, payload) {
    commit("SET_LOADING", true)

    await this.app.$api.USER_ACTIVITIES.postModel(payload)
      .then(({ data }) => {
        commit("PUSH_LIST", data)
      })
      .catch((err) => {
        this.app.$toast.error(err.message)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  async updateActivity({ commit }, payload) {
    commit("SET_LOADING", true)

    await this.app.$api.USER_ACTIVITIES.patchWithParams(payload.id, payload)
      .then(() => {
        commit("UPDATE_LIST", payload)
      })
      .catch((err) => {
        this.app.$toast.error(err.message)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  async deleteActivity({ commit }, payload) {
    commit("SET_LOADING", true)

    await this.app.$api.USER_ACTIVITIES.deleteWithParams(payload.id)
      .then(() => {
        commit("REMOVE_LIST", payload)
      })
      .catch((err) => {
        this.app.$toast.error(err.message)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
}
