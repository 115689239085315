
import ChatAvatar from "~/components/Chat/ChatAvatar.vue"

export default {
  name: "ChatItem",
  components: {
    ChatAvatar,
  },
  props: {
    message: {
      type: String,
      default: "",
    },
    hasAvatar: {
      type: Boolean,
      default: false,
    },
    userDetail: {
      type: Object,
      default: null,
    },
    date: {
      type: [String, Object],
      default: "",
    },
    self: {
      type: Boolean,
      default: false,
    },
    uself: {
      type: Boolean,
      default: false,
    },
    file: {
      type: [Object, File],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typesToPreview: ["image/png", "image/jpg", "image/jpeg", "image/webp"],
    }
  },
  computed: {
    formattedDate() {
      return this.$dayjs(this.date).format("LT")
    },
  },
  methods: {
    handleDownloadFile() {
      this.download(this.file.url, this.file.file_name)
    },
    download(uri, name) {
      const link = document.createElement("a")
      link.download = name
      link.href = uri
      link.target = "_blank"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
