import { render, staticRenderFns } from "./ChatList.vue?vue&type=template&id=5c8f0f27&scoped=true&"
import script from "./ChatList.vue?vue&type=script&lang=js&"
export * from "./ChatList.vue?vue&type=script&lang=js&"
import style0 from "./ChatList.vue?vue&type=style&index=0&id=5c8f0f27&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c8f0f27",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatConnections: require('/home/ubuntu/coherent-frontend/components/Chat/ChatConnections.vue').default,SideSheet: require('/home/ubuntu/coherent-frontend/components/SideSheet.vue').default})
