export default {
  async fetchSurveyData({ commit, getters }, serialize = true) {
    const response = getters.surveyResponse

    const $api = this.app.$api

    const data = {
      clients: [],
      activities: [],
      practitioners: [],
    }

    for await (const field of Object.keys(response)) {
      switch (field) {
        case "clients":
          if (!response[field].length) {
            const res = await $api.SURVEY_CLIENTS.get()
            data.clients = res.data.map((x) => {
              return {
                name: x.id,
                value: `${x.first_name} ${x.last_name}`,
              }
            })
          } else {
            data.clients = response.clients
          }
          break
        case "activities":
          if (!response[field].length) {
            const res = await $api.SURVEY_ACTIVITY.get()
            data.activities = res.data
          } else {
            data.activities = response.activities
          }
          break
        case "practitioners":
          if (!response[field].length) {
            const res = await $api.SURVEY_PRACTITIONERS.get()
            data.practitioners = res.data.map((x) => {
              return {
                name: x.id,
                value: x.name,
              }
            })
          } else {
            data.practitioners = response.practitioners
          }
          break
      }
    }

    for (const field of Object.keys(data)) {
      commit("SET_RESPONSE", { field, payload: data[field] })
    }

    if (serialize) commit("SET_SERIALIZE", true)
  },
}
