
export default {
  name: "ClarkTag",
  props: {
    styleval: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "unnamed",
    },
    value: {
      type: [Number, String],
      default: -1,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleOnClose() {
      if (this.disabled) return
      this.$emit("remove", this.value)
    },
  },
}
