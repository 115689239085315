import jsCookie from "js-cookie"

const cookieDomain = process.env.COOKIE_DOMAIN

export default {
  async login({ dispatch }, data) {
    await this.app.$api.LOGIN.postModel(data)
      .then(async (res) => {
        // Get decoded token and calculate expire time
        const token = res.data.access_token
        const expires = res.data.expires_in
        const cookieDomain = process.env.COOKIE_DOMAIN
        // Set token and expires to state
        await dispatch("setData", {
          token,
          expires,
        })

        // Show successfull notification and redirect
        // Set token and expires to cookie
        jsCookie.set("token", token, {
          expires,
        })

        jsCookie.set("token_expires", expires, {
          expires,
        })

        jsCookie.set(cookieDomain, token, {
          path: "/",
          domain: "mycoherenthealth.com",
        })

        const hasRedirect = jsCookie.get("redirect")
        this.$router.push(hasRedirect || "/dashboard")
        jsCookie.remove("redirect")
      })
      .catch((err) => {
        throw err
      })
  },
  async loginbytoken({ dispatch }, data) {
    await this.app.$api.LOGIN.postModel(data)
      .then(async (res) => {
        // Get decoded token and calculate expire time
        const token = res.data.access_token
        const expires = res.data.expires_in
        // Set token and expires to state
        await dispatch("setData", {
          token,
          expires,
        })

        // Show successfull notification and redirect
        // Set token and expires to cookie
        jsCookie.set("token", token, {
          expires,
        })

        jsCookie.set("token_expires", expires, {
          expires,
        })

        jsCookie.set(cookieDomain, token, {
          path: "/",
          domain: "mycoherenthealth.com",
        })

        this.$router.push("/user/profile/edit")
      })
      .catch((err) => {
        throw err
      })
  },
  async register(vuexContext, payload) {
    await this.app.$api.REGISTER.post(payload)
  },
  async setData({ commit }, data) {
    commit("setData", data)
    const user = await this.app.$api.ME.get()
    localStorage.setItem("uniqueId", user.data.id)
    commit("setUser", user.data)
  },
  async logout({ commit }, request = true, path = "") {
    const cookieDomain = process.env.COOKIE_DOMAIN
    jsCookie.remove(cookieDomain, { path: "/", domain: "mycoherenthealth.com" })
    jsCookie.remove("token")
    jsCookie.remove("token_expires")

    if (request) await this.app.$api.LOGOUT.post()
    if (path) jsCookie.set("redirect", path)
    commit("clearData")

    window.location.href = "/login"
  },
  async fetchDataFromCookie({ dispatch }) {
    const cookieDomain = process.env.COOKIE_DOMAIN
    return await dispatch("setData", {
      token: jsCookie.get("token"),
      expires: jsCookie.get("token_expires"),
      "mycoherenthealth.com": jsCookie.get(cookieDomain),
    })
  },

  autoLogout({ commit }) {
    const cookieDomain = process.env.COOKIE_DOMAIN
    jsCookie.remove(cookieDomain, { path: "/", domain: "mycoherenthealth.com" })
    jsCookie.remove("token")
    jsCookie.remove("token_expires")
    commit("clearData")
  },
}
