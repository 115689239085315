/* eslint-disable no-prototype-builtins */

export default function ({ $axios }, inject) {
  const isDev = process.env.NODE_ENV === "development"

  const toCapitalize = (text) => {
    if (text) return text.charAt(0).toUpperCase() + text.slice(1)
    else return ""
  }

  const imageAPI = (url) => {
    // if (url) return !isDev ? url : process.env.API_URL.split("/api/")[0] + url;
    if (url) return url
    return require("~/assets/images/svgs/practitioner-avatar.svg")
  }

  const isEmpty = (obj) => {
    if (obj && obj.length === 0) return true
    if (obj === null || obj === undefined) return true

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) return false
    }

    return JSON.stringify(obj) === JSON.stringify({})
  }

  const getModality = (arr) => {
    if (Array.isArray(arr)) {
      return arr
    }
  }

  const convert2StringModality = ({ modality = [] }) => {
    if (modality) {
      modality = modality.map(({ name }) => name).join(", ")
      return modality
    }
    return ""
  }

  const printForm = ({ url }) => {
    const link = document.createElement("a")
    link.href = url
    link.target = "_blank"
    document.body.appendChild(link)
    link.click()
  }

  const isValidURL = (link) => {
    return link.indexOf("http://") === 0 || link.indexOf("https://") === 0
  }

  const flat = (arr) => [].concat(...arr)

  const buildFormData = (data) => {
    const formData = new FormData()
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key])
      }
    }
    return formData
  }

  const makeID = (length = 5) => {
    let result = ""
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const isValidEmail = (email) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  inject("toCapitalize", toCapitalize)
  inject("imageAPI", imageAPI)
  inject("isEmpty", isEmpty)
  inject("isValidEmail", isValidEmail)
  inject("isDev", isDev)
  inject("getModality", getModality)
  inject("printForm", printForm)
  inject("isValidURL", isValidURL)
  inject("flat", flat)
  inject("convert2StringModality", convert2StringModality)
  inject("buildFormData", buildFormData)
  inject("makeID", makeID)
}
