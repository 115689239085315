
export default {
  name: "AccessLayout",
  mounted() {
   // Select the <body> element
    const body = document.querySelector('body');
    
    // Add a class to the <body> element
    body.classList.add('bgcolor');
  },
}
