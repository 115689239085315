const createConvo = (item) => {
  const { user } = item

  const convo = {
    messages: [],
    loading: true,
    page: 1,
    lastPage: 1,
    hasNewMessages: false,
    typing: false,
  }

  if (item.type === "group") {
    convo.user = {
      name: item.name,
      avatar: item.users.map((user) => user.avatar),
    }

    convo.type = "group"
  } else {
    convo.user = {
      name: user ? user.name : item.name,
      avatar: user ? user.avatar : item.avatar,
    }
  }

  return {
    field: item.type === "group" ? item.id + 100000 : user ? user.id : item.id,
    payload: convo,
  }
}

export default createConvo
