
import { mapGetters } from "vuex"
import { cloneDeep, uniq, isNumber, isEqualWith } from "lodash"
import createConvo from "~/helpers/createConvo"

import ChatDesktop from "~/components/Chat/ChatDesktop.vue"
import ChatMobile from "~/components/Chat/ChatMobile/index.vue"

export default {
  name: "AppChat",
  components: {
    ChatDesktop,
    ChatMobile,
  },
  data() {
    return {
      minimized: true,
      convos: {},
      actives: [],
      refreshConnections: null,
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser", "isAuthenticated"]),
    ...mapGetters(["activeChat", "groupChats"]),
    componentName() {
      return this.$device.isDesktop ? "ChatDesktop" : "ChatMobile"
    },
    newMessages() {
      const hasNew = []
      console.log(this.convos)
      for (const id in this.convos) {
        if (this.convos[id].hasNewMessages) {
          hasNew.push(Number(id))
        }
      }

      this.getUser.connections.forEach((x) => {
        if (x.message_count) {
          hasNew.push(Number(x.user.id))
          this.$store.commit("SET_LAST_CHAT", x.user.id)
        }
      })

      return hasNew
    },
  },
  watch: {
    activeChat: {
      handler(payload) {
        if (!payload) return
        if (!payload.user) return
        this.setConvos(createConvo(payload.user))
        this.$store.commit("SET_CHAT_ITEM", null)
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$echo
      .channel(`coherent.chat.${this.getUser.id}`)
      .listen(".message-sent", (payload) => {
        if (isNumber(payload?.message?.typing)) {
          const { from_user_id, typing } = payload.message
          this.pushMessages({
            field: from_user_id,
            payload: { typing: typing === 1 },
          })
        } else {
          this.parsePusherMessage(payload.message)
        }

        this.$forceUpdate()
      })

    this.refreshConnections = setInterval(() => {
      if (!this.isAuthenticated) return
      this.$store.dispatch("auth/fetchDataFromCookie")
      this.$store.dispatch("fetchGroupChats")
    }, 1 * 60 * 1000)

    this.CLOSE_CHAT_BOX()
  },
  beforeDestroy() {
    clearInterval(this.refreshConnections)
  },
  methods: {
    CLOSE_CHAT_BOX(){
      // added api to check user's chat box
      this.$api.CLOSE_CHAT_BOX.postModel({
        to_user_id: this.getUser.id,
        is_all_chat_box: true
      })
    },
    SET_MINIMIZED(payload) {
      this.minimized = payload
      this.$forceUpdate()
    },
    SET_CONVOS({ field, payload }) {
      this.$set(this.convos, field, payload)
      this.$forceUpdate()
    },
    UNSET_CONVOS(field) {
      if (!this.convos[field]) return
      delete this.convos[field]
      this.POP_ACTIVES(field)
      this.$forceUpdate()
    },
    PUSH_ACTIVES(payload) {
      const actives = cloneDeep(this.actives)
      this.$set(this, "actives", uniq([...actives, Number(payload)]))
      this.$forceUpdate()
    },
    POP_ACTIVES(payload) {
      const idx = this.actives.indexOf(Number(payload))
      if (idx > -1) this.actives.splice(idx, 1)
      this.$forceUpdate()
    },
    setConvos({ field, payload }) {
      const convos = this.convos

      if (!convos[field]) {
        this.SET_CONVOS({ field, payload })
      }

      if (convos[field] && payload.type === "group") {
        const diff = [
          convos[field].user.name === payload.user.name,
          isEqualWith(convos[field].user.avatar, payload.user.avatar),
        ]

        if (diff.includes(false)) {
          this.SET_CONVOS({ field, payload })
        }
      }

      const foundConn = this.getUser.connections.find(
        (x) => x.user.id === field
      )

      if (foundConn) {
        this.$store.dispatch("auth/fetchDataFromCookie")
        this.$forceUpdate()
      }

      if (!payload.hasNewMessages) this.PUSH_ACTIVES(field)
    },
    pushMessages({ field, payload }) {
      const convos = this.convos
      const convo = convos[field]

      if (!convo) return

      this.SET_CONVOS({ field, payload: { ...convo, ...payload } })
    },
    async sendMessage({
      field,
      payload,
      from_user_id,
      file = null,
      type = null,
    }) {
      const convo = this.convos[field]
      if (!convo) return

      this.$store.commit("SET_LAST_CHAT", field)

      const messages = convo.messages

      const idTemp = this.$makeID(3)

      const toBePushed = [
        ...messages,
        {
          id: idTemp,
          from_user_id,
          message: payload,
          file,
          created_at: this.$dayjs(),
          loading: true,
        },
      ]

      const lastItem = toBePushed[toBePushed.length - 1]

      const user = {
        name: this.getUser.name,
        avatar: this.getUser.avatar,
      }

      if (type === "group") lastItem.user_detail = user
      else lastItem.from_user_detail = user

      this.pushMessages({
        field,
        payload: {
          messages: toBePushed,
        },
      })

      const postModel = {
        message: payload,
      }

      if (type === "group") {
        postModel.group_id = field - 100000
      } else {
        postModel.to_user_id = field
      }

      if (file) postModel.file = file

      const formData = new FormData()

      for (const key in postModel) {
        formData.append(key, postModel[key])
      }

      const { data } = await this.$api[
        type === "group" ? "CONVERSATIONS" : "MESSAGE_SEND"
      ].postModel(formData)

      const tempMessages = [...this.convos[field].messages]
      const foundTempMessageIdx = tempMessages.findIndex(
        (message) => message.id === idTemp
      )

      if (foundTempMessageIdx === -1) return

      const tempMessage = { ...tempMessages[foundTempMessageIdx] }
      tempMessages.splice(foundTempMessageIdx, 1)

      tempMessage.id = data.id
      tempMessage.loading = false

      if (data.file) {
        tempMessage.file = JSON.parse(data.file)
      }

      this.pushMessages({
        field,
        payload: {
          messages: [...tempMessages, tempMessage],
        },
      })
    },
    async parsePusherMessage(payload) {
      const {
        id,
        from_user_id,
        group_id,
        message,
        created_at,
        from_user_detail,
        user_detail,
        file,
      } = payload
      const type = group_id ? "group" : "convo"

      if (from_user_id !== this.getUser.id) {
        this.$store.commit(
          "SET_LAST_CHAT",
          type === "group" ? Number(group_id) + 100000 : from_user_id
        )
      } else return

      const detail = type === "group" ? user_detail : from_user_detail

      const user = {
        id: from_user_id,
        name: detail.from_user_name,
        avatar: detail.avatar,
      }

      const field = type === "group" ? Number(group_id) + 100000 : from_user_id

      const convo = this.convos[field]

      if (convo) {
        this.pushMessages({
          field,
          payload: {
            messages: [
              ...convo.messages,
              {
                id,
                from_user_id,
                message,
                created_at,
                file,
                from_user_detail,
                user_detail,
              },
            ],
            hasNewMessages: !this.actives.includes(field),
            type,
          },
        })

        try {
          const audio = new Audio("/sounds/message.mp3")
          audio.play()
        } catch {}
      } else {
        if (type === "group") {
          const foundGroup = this.groupChats.find(
            (x) => x.id + 100000 === field
          )
          if (!foundGroup) await this.$store.dispatch("fetchGroupChats")
          const temp = this.groupChats.find((x) => x.id + 100000 === field)
          user.name = foundGroup ? foundGroup.name : temp.name
          user.users = foundGroup ? foundGroup.users : temp.users
          user.type = type
          user.id = field - 100000
        }

        const createdConvo = createConvo(user)
        this.setConvos({
          field: createdConvo.field,
          payload: { ...createdConvo.payload, hasNewMessages: true, type },
        })
      }
    },
  },
}
