import { render, staticRenderFns } from "./ChatGroupAvatar.vue?vue&type=template&id=5929d5a1&scoped=true&"
import script from "./ChatGroupAvatar.vue?vue&type=script&lang=js&"
export * from "./ChatGroupAvatar.vue?vue&type=script&lang=js&"
import style0 from "./ChatGroupAvatar.vue?vue&type=style&index=0&id=5929d5a1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5929d5a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatAvatar: require('/home/ubuntu/coherent-frontend/components/Chat/ChatAvatar.vue').default})
