
import ChatAvatar from "~/components/Chat/ChatAvatar.vue"

export default {
  name: "ChatGroupAvatar",
  components: {
    ChatAvatar,
  },
  props: {
    avatars: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    parsedAvatars() {
      return [...this.avatars].slice(0, 2)
    },
  },
}
