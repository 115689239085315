const isDev = process.env.NODE_ENV === "development"
const endpoint = isDev ? "/api" : process.env.API_URL

const routes = {
  LOGIN: "login",
  LOGOUT: "logout",
  REGISTER: "register",
  REFRESH_TOKEN: "refresh",
  ME: "me",
  CLIENTS: "clients",
  CLIENTINFO: "clientinfo",
  ADDCLIENT: "client/create",
  GETCLIENT: "client/fetch",
  COLLABORATORS: "collaborators",
  COLLABORATORS_FILTER: "client/collaborators/filter",
  TEMPLATES: "templates",
  TEMPLATE_RENAME: "template/rename",
  NOTES: "notes",
  CLIENTSNOTE: "client/notes",
  NOTESPDFUPLOAD: "notes/survey-pdf",
  NOTESFILEUPLOAD: "note-files",
  NOTESFILESDELETE: "note-files/delete",
  PRACTITIONERS: "practitioners",
  PRACTITIONERINFO: "practitionerProfile",
  PRACTITIONERS_FILTER: "filter/location",
  USER_PROFILE: "profile",
  GET_USER_COLLABORATOR: "getconnections",
  USER_COLLABORATOR:"connections",
  SETTINGS: "settings",
  FEEDBACK: "feedback",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  CHECK_FP_TOKEN: "check-reset-token",
  FORMS: "forms",
  FORMS_UPLOAD: "forms/upload",
  FORMS_RENAME: "forms/rename",
  USER_CONNECTION: "user-connection",
  SURVEY_CLIENTS: "survey-js/clients",
  SURVEY_ACTIVITY: "survey-js/activities",
  SURVEY_PRACTITIONERS: "survey-js/practitioners",
  MESSAGE_LATEST: "messages/latest",
  IS_TYPING: "messages/typing",
  MESSAGE_SEND: "messages",
  DOWNLOAD_FORMS: "/download/all/form",
  DOWNLOAD_NOTES: "/download/all/note",
  GROUPS: "groups",
  CLIENT_PATTERN_WELLNESS: "client-pattern-wellness",
  CLIENT_PATTERN_WELLNESS_REPORT: "client-pattern-wellness/report",
  CLIENT_PATTERN_WELLNESS_DELETE: "client-pattern-wellness/delete",
  ACTIVITIES: "activities",
  GETACTIVITY: "get/activity",
  COLLABORATOR_INVITE: "invite/client-collaborator",
  OFFLINE: "user-status-update",
  USER_ACTIVITIES: "user-activities",
  GROUP_CHATS: "group-chats",
  CONVERSATIONS: "conversations",
  CHECKOUT_SESSIONS: "checkout-sessions",
  SET_READ_MESSAGE: "messages/markread",
  CLOSE_CHAT_BOX: "messages/closechatbox",
  USERSECTION: "usersection",
  USERSECTION_STORE: "/usersection/store",
  USERSECTION_DELETE: "usersection/delete",
  USERSECTION_EDIT: "/usersection/edit",
  CONNECTIONS_DELETE: "connections/delete",
  MODALITY: "activities",
  STORE_MODALITY: "add/activities",
  GEOLOCATION: "geolocation",
  GEOLOCATIONDETAIL: "geolocationdetail",
  CITIES: "cities",
  EMAILVERIFY: "email-verify",
  NOTESFILEDOWNLOAD: "notes/download",
  DOWNLOAD_CLIENT_NOTES: "/download/client/note",
  USERS: "users",
  USERFLAGS: "user/update/flags",
  USERGROUP: 'user/group',
  USEREMAIL: 'user/emails',
  DELETEPROFILEPIC: 'deletepic',
  ALLPRACTITIONERS: 'all/practitioners/list',
  GROUPDETAILS: 'group/details',
  ALLMODALITY: 'allActivities',
  PRACTITIONERCONNECTION: 'practitionerConnection',
}

export default function ({ $axios, store, $toast, route, redirect }, inject) {
  const api = $axios.create({ baseURL: endpoint })

  api.onRequest((config) => {
    const token = store.getters["auth/getToken"]

    if (!token) return config

    try {
      $axios.setToken(token, "Bearer")
      config.headers["Access-Control-Allow-Origin"] = "*"
      config.headers.Authorization = "Bearer " + token
    } catch {}

    return config
  })

  api.onError((error) => {
    if (
      error?.response?.data?.error_code === 1001 &&
      error.response.status === 401
    ) {
      store.dispatch("auth/logout", false, route.fullPath)
    }

    if (error?.response?.data?.redirect === "payments") {
      store.dispatch("auth/autoLogout")
      redirect("/access/denied")
    }
  })

  class ApiServiceBase {
    constructor(axios, route) {
      this._axios = axios
      this._route = route
    }

    get axios() {
      return this._axios
    }

    get route() {
      return this._route
    }
  }

  class ApiRouteBase extends ApiServiceBase {
    async get(token) {
      return await this.axios.get(this.route, {
        headers: {
          Authorization: token,
        },
      })
    }

    async getWithParams(params) {
      return await this.axios.get(this.route + "/" + params)
    }

    async getWithQS(qs) {
      return await this.axios.get(this.route + "?" + qs)
    }

    async post() {
      return await this.axios.post(this.route)
    }

    async postModel(model, mm) {
      return await this.axios.post(this.route, !model ? mm : model)
    }

    async postWithParams(params, model) {
      return await this.axios.post(this.route + "/" + params, model)
    }

    async postWithQS(params, model) {
      return await this.axios.post(this.route + "?" + params, model)
    }

    async patch(model) {
      return await this.axios.patch(this.route, model, {})
    }

    async patchWithParams(params, model, options = {}) {
      return await this.axios.patch(this.route + "/" + params, model, options)
    }

    async delete(model) {
      return await this.axios.delete(this.route + "/", {
        data: model,
      })
    }

    async deleteWellness(model) {
      return await this.axios.post(this.route, model)
    }

    async deleteWithParams(params, model) {
      return await this.axios.delete(this.route + "/" + params, model)
    }
  }

  inject("api", api)

  for (const route in routes) {
    api[route] = new ApiRouteBase(api, routes[route])
  }
}
