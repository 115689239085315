
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
export default {
  name: "CookieBannerModal",
  methods: {
    handleAccept(){
      this.$emit("acceptcookie")
    },
    handleClose() {
      this.$emit("closecookiebanenr")
    },
  }
}
