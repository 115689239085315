
import { mapGetters } from "vuex"

import ChatBubble from "~/components/Chat/ChatBubble.vue"
import ChatAvatar from "~/components/Chat/ChatAvatar.vue"
import ChatConnections from "~/components/Chat/ChatConnections.vue"
import ModalCreateGroup from "~/components/Chat/ModalCreateGroup.vue"
import ModalSendMessage from "~/components/Chat/ModalSendMessage.vue"
import createConvo from "~/helpers/createConvo"

export default {
  name: "AppChatDesktop",
  components: {
    ChatBubble,
    ChatAvatar,
    ChatConnections,
    ModalCreateGroup,
    ModalSendMessage
  },
  props: {
    newMessages: {
      type: Array,
      default: () => [],
    },
    convos: {
      type: Object,
      required: true,
    },
    actives: {
      type: Array,
      required: true,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      createGroup: {
        visible: false,
      },
      sendMessage: {
        visible: false,
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    avatar() {
      return this.getUser.avatar
    },
    name() {
      return this.getUser.name
    },
  },
  methods: {
    openChatWindow(item){
      setTimeout(() => {
        document
          .getElementById("item" + item.user.id)
          .classList.remove("has-border")
      }, 500)
      this.$emit('setConvos', createConvo(item))
      this.$emit("update")
      // call api for read message here
      this.$api.SET_READ_MESSAGE.postModel({
        to_user_id: item.initiating_user_id,
        from_user_id: item.receiving_user_id,
      })
      .then((res) => {
          setTimeout(() => {
            document
              .getElementById("item" + item.user.id)
              .classList.remove("has-border")
          }, 500)
      })
    },
    toggle() {
      this.$emit("toggle", !this.minimized)
    },
    handleSendMessage(){
      if (this.minimized) this.toggle()
      this.sendMessage.visible = true
    },
    handleCreateGroup() {
      if (this.minimized) this.toggle()
      this.createGroup.visible = true
    },
    handleClose(id) {
      this.$emit("close", id)
      this.$forceUpdate()
    },
    handleUpdate() {
      this.$forceUpdate()
    },
    emit(field, payload) {
      this.$emit(field, payload)
      this.$forceUpdate()
    },
  },
}
