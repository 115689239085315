
export default {
  name: "ChatInput",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    sent: {
      type: Number,
      default: 0,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    sent: {
      handler() {
        this.$refs["chat-input"].innerHTML = "<p class='font-light' style='font-size: 11px !important;'><br /></p>"
      },
    },
  },
  methods: {
    handleChange(e) {
      if (this.disabled) return
      this.$emit("change", e.target.innerText || "")
    },
    handleEnter(e) {
      if (e.which === 13) {
        e.preventDefault()
        this.$emit("enter")
      }
    },
  },
}
