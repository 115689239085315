
import { mapGetters } from "vuex"

export default {
  name: "ChatAvatar",
  props: {
    size: {
      type: String,
      default: "32px",
    },
    imagesize: {
      type: String,
      default: "",
    },
    border: {
      type: String,
      default: "none",
    },
    src: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    userconnections: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "practitioner",
    },
    unreadmessage: {
      type: String,
      default: "",
    },
    imgsize: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    avatar() {
      return this.src || require(`~/assets/images/svgs/${this.type}-avatar.svg`)
    },
    online() {
      if (!this.id) return true
      const found = this.getUser.connections.find((x) => {
        return Number(x.user.id) === Number(this.id)
      })

      if (found) return found.user.status === "online"
      return false
    },
    connectiononline() {
      if (!this.id) return true
      const found = this.getUser.connections.find((x) => {
        return Number(x.user.id) === Number(this.id)
      })

      if (found) return found.user.status === "online"
      return false
    },
    avatarStyle() {
      // const size = "32px"
      return {
        width: this.size,
        height: this.size,
        border: this.border,
      }
    },
    avatarSize() {
      return {
        width: this.imgsize,
        height: this.imgsize,
      }
    },
    avatarImgStyle() {
      return {
        width: this.imagesize,
        height: this.imagesize,
      }
    },
  },
}
