
export default {
  name: "SideSheet",
  model: {
    prop: "visible",
    event: "visible",
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleClickBack() {
      if (!this.visible) return
      this.$emit("visible", false)
    },
  },
}
