import { render, staticRenderFns } from "./ChatDesktop.vue?vue&type=template&id=af90fa32&scoped=true&"
import script from "./ChatDesktop.vue?vue&type=script&lang=js&"
export * from "./ChatDesktop.vue?vue&type=script&lang=js&"
import style0 from "./ChatDesktop.vue?vue&type=style&index=0&id=af90fa32&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af90fa32",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatBubble: require('/home/ubuntu/coherent-frontend/components/Chat/ChatBubble.vue').default,ChatAvatar: require('/home/ubuntu/coherent-frontend/components/Chat/ChatAvatar.vue').default,ChatConnections: require('/home/ubuntu/coherent-frontend/components/Chat/ChatConnections.vue').default})
