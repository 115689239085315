
import { mapGetters } from "vuex"
// components
import Hotjar from '@hotjar/browser';
import Chat from "~/components/Chat"
import AddClient from "~/components/Client/AddClient.vue"
import CookiBanner from "~/components/CookieBanner.vue"

export default {
  name: "DefaultLayout",
  components: { Chat, AddClient, CookiBanner },
  middleware: ["auth", "consent"],
  data() {
    return {
      defaultClass: false,
      showcookiebanner: true,
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "getUser"]),
    isEditNote() {
      return (
        ((this.$route.name === "notes-edit-noteid" && window.innerWidth > 780) ||  (this.$router.history.current.path === "/client/edit")) ??
        ""
      )
    },
    isDashboard() {
      return this.$route.name === "Dashboard" ?? ""
    },
  },
  mounted(){
      if(this.$cookies.get("cookiebanenr")){
        this.showcookiebanner = false
      }
  },
  methods:{
    acceptcookie(){
      const hotJarId = process.env.HOTJAR_SITE_ID
      const hotJarVersion = process.env.HOTJAR_VERSION
      const userID = this.getUser.id || ""
      Hotjar.init(hotJarId, hotJarVersion);
      Hotjar.identify(userID) // For add custom value Hotjar.identify(userID, {first_name: firstName,color: favoriteColor});
      this.closecookiebanenr()
    },
    closecookiebanenr(){
      this.showcookiebanner = false
      this.$cookies.set("cookiebanenr", false, "1d")
    }
  }
}
