
export default {
  name: "ButtonDefaultMain",
  props: {
    buttonLabel: {
      type: String,
      default: "ButtonLabel",
    },
    to: {
      type: String,
      default: "/",
    },
    prevent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    useAnchor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(e) {
      if (this.useAnchor && this.to) {
        window.location = this.to
      } else if (this.prevent) {
        e.preventDefault()
        this.$emit("click", e)
      } else {
        this.$router.push(this.to)
        this.$emit("click", e)
      }
    },
  },
}
