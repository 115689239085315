
import "vue-select/dist/vue-select.css"
import { mapGetters, mapMutations } from "vuex"
import ButtonDefaultMain from "~/components/Tools/ButtonDefaultMain.vue"
import ButtonDefaultSecondary from "~/components/Tools/ButtonDefaultSecondary.vue"
import ChatAvatar from "~/components/Chat/ChatAvatar.vue"

export default {
  name: "ModalSendMessage",
  components: {
    ButtonDefaultMain,
    ButtonDefaultSecondary,
    ChatAvatar,
  },
  props: {
    groupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      selected: "",
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser", "isAuthenticated"]),
    ...mapGetters(["groupChats"]),
    connections() {
      const sorted = []
      if (!this.isAuthenticated || !this.getUser || !this.getUser.connections) {
        return sorted
      }
      const connections = [...this.getUser.connections]

      connections.forEach((x) => {
        sorted.push({
          id: x.user.id,
          name: x.user.name,
          avatar: x.user.avatar,
        })
      })

      return sorted
    },
  },
  mounted() {
    if (!this.groupId) return

    const foundGroup = this.groupChats.find(
      (x) => x.id === Number(this.groupId) - 100000
    )
    if (!foundGroup) this.handleClose()

    const { name, users } = foundGroup
    this.groupName = name
    this.selected = [...users].filter((x) => x.id !== this.getUser.id)
  },
  methods: {
    ...mapMutations(["SET_CHAT_ITEM"]),
    handleClose() {
      this.$emit("close", false)
    },
    handleSelect(val) {
      if(val.id){
        this.selected = val.id
      }
    },
    handleSend() {
      const connections = [...this.getUser.connections]
      connections.forEach((x) => {
        if(x.user.id === this.selected){
          this.$emit("openChat", x)
          this.handleClose()
        }
      })
    },
  },
}
