
import ChatActivator from "~/components/Chat/ChatMobile/ChatActivator.vue"
import ChatList from "~/components/Chat/ChatMobile/ChatList.vue"
import ChatPanel from "~/components/Chat/ChatMobile/ChatPanel.vue"

export default {
  name: "AppChatMobile",
  components: {
    ChatActivator,
    ChatList,
    ChatPanel,
  },
  props: {
    newMessages: {
      type: Array,
      default: () => [],
    },
    convos: {
      type: Object,
      required: true,
    },
    actives: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    isOpen: {
      handler(isOpen) {
        const html = document.getElementsByTagName("html")[0]
        if (isOpen) {
          html.style.overflow = "hidden"
        } else {
          html.style.overflow = ""
        }
      },
    },
  },
  methods: {
    handleClickActivator() {
      this.isOpen = !this.isOpen
    },
    emit(field, payload) {
      this.$emit(field, payload)
      this.$forceUpdate()
    },
  },
}
