
import "vue-select/dist/vue-select.css"
import { mapGetters, mapMutations } from "vuex"

import ButtonDefaultMain from "~/components/Tools/ButtonDefaultMain.vue"
import ButtonDefaultSecondary from "~/components/Tools/ButtonDefaultSecondary.vue"
import ChatAvatar from "~/components/Chat/ChatAvatar.vue"
import Clark from "~/components/Clark.vue"

export default {
  name: "ModalCreateGroup",
  components: {
    ButtonDefaultMain,
    ButtonDefaultSecondary,
    ChatAvatar,
    Clark,
  },
  props: {
    groupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      temp: null,
      groupName: "",
      selected: [],
    }
  },
  computed: {
    ...mapGetters("auth", ["getUser", "isAuthenticated"]),
    ...mapGetters(["groupChats"]),
    connections() {
      const sorted = []
      if (!this.isAuthenticated || !this.getUser || !this.getUser.connections) {
        return sorted
      }
      const connections = [...this.getUser.connections]

      connections.forEach((x) => {
        sorted.push({
          id: x.user.id,
          name: x.user.name,
          avatar: x.user.avatar,
        })
      })

      return sorted
    },
  },
  mounted() {
    if (!this.groupId) return

    const foundGroup = this.groupChats.find(
      (x) => x.id === Number(this.groupId) - 100000
    )
    if (!foundGroup) this.handleClose()

    const { name, users } = foundGroup
    this.groupName = name
    this.selected = [...users].filter((x) => x.id !== this.getUser.id)
  },
  methods: {
    ...mapMutations(["SET_CHAT_ITEM"]),
    handleClose() {
      this.$emit("close", false)
    },
    handleSubmit() {
      const { groupName, selected } = this
      if (!groupName || !selected.length) {
        return this.$toast.error("Please fill all the fields")
      }

      const gid = this.groupId

      this.loading = true

      const payload = {
        name: groupName,
        users: selected.map((x) => x.id),
      }

      const req = gid
        ? this.$api.GROUP_CHATS.postWithParams(
            `${Number(gid) - 100000}/update`,
            payload
          )
        : this.$api.GROUP_CHATS.postModel(payload)

      req
        .then(({ data }) => {
          this.$toast.success(
            `Group ${gid ? "edited" : "created"} successfully`
          )
          this.$store.dispatch("fetchGroupChats")

          this.SET_CHAT_ITEM({
            user: {
              type: "group",
              id: data.id,
              name: data.name,
              users: [this.getUser, ...selected],
            },
          })

          this.handleClose()
        })
        .catch((res) => {
          this.$toast.error(res.response.data.message)
          this.handleClose()
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSelect(val) {
      const found = this.selected.find((x) => x.id === val.id)
      if (found) return
      this.selected.push(val)
    },
    removeSelected(id) {
      const idx = this.selected.findIndex((x) => x.id === id)
      this.selected.splice(idx, 1)
    },
  },
}
