
import ChatConnections from "~/components/Chat/ChatConnections.vue"
import SideSheet from "~/components/SideSheet.vue"
import ModalCreateGroup from "~/components/Chat/ModalCreateGroup.vue"
import ModalSendMessage from "~/components/Chat/ModalSendMessage.vue"
import createConvo from "~/helpers/createConvo"

export default {
  name: "ChatList",
  components: {
    ChatConnections,
    SideSheet,
    ModalCreateGroup,
    ModalSendMessage
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    newMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      createGroup: {
        visible: false,
      },
      sendMessage: {
        visible: false,
      },
      convos: {
        type: Object,
        required: true,
      },
    }
  },
  methods: {
    handleUpdate() {
      this.$forceUpdate()
    },
    handleSendMessage(){
      this.sendMessage.visible = true
    },
    openChatWindow(item){
      setTimeout(() => {
        document
          .getElementById("item" + item.user.id)
          .classList.remove("has-border")
      }, 500)
      this.$emit('setConvos', createConvo(item))
      this.$emit("update")
      // call api for read message here
      this.$api.SET_READ_MESSAGE.postModel({
        to_user_id: item.initiating_user_id,
        from_user_id: item.receiving_user_id,
      })
      .then((res) => {
          setTimeout(() => {
            document
              .getElementById("item" + item.user.id)
              .classList.remove("has-border")
          }, 500)
      })
    },
  },
}
