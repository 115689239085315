
export default {
  name: "ChatActivator",
  props: {
    hasNewMessages: {
      type: Boolean,
      default: false,
    },
  },
}
