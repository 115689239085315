import { render, staticRenderFns } from "./ChatPanel.vue?vue&type=template&id=2b5686aa&scoped=true&"
import script from "./ChatPanel.vue?vue&type=script&lang=js&"
export * from "./ChatPanel.vue?vue&type=script&lang=js&"
import style0 from "./ChatPanel.vue?vue&type=style&index=0&id=2b5686aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5686aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatAvatar: require('/home/ubuntu/coherent-frontend/components/Chat/ChatAvatar.vue').default,ChatGroupAvatar: require('/home/ubuntu/coherent-frontend/components/Chat/ChatGroupAvatar.vue').default,ChatItem: require('/home/ubuntu/coherent-frontend/components/Chat/ChatItem.vue').default,ChatTyping: require('/home/ubuntu/coherent-frontend/components/Chat/ChatTyping.vue').default,ChatInput: require('/home/ubuntu/coherent-frontend/components/Chat/ChatInput.vue').default,SideSheet: require('/home/ubuntu/coherent-frontend/components/SideSheet.vue').default})
