import { render, staticRenderFns } from "./index.vue?vue&type=template&id=724f9756&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=724f9756&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724f9756",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatActivator: require('/home/ubuntu/coherent-frontend/components/Chat/ChatMobile/ChatActivator.vue').default,ChatList: require('/home/ubuntu/coherent-frontend/components/Chat/ChatMobile/ChatList.vue').default,ChatPanel: require('/home/ubuntu/coherent-frontend/components/Chat/ChatMobile/ChatPanel.vue').default})
