import { render, staticRenderFns } from "./ModalCreateGroup.vue?vue&type=template&id=528f72de&scoped=true&"
import script from "./ModalCreateGroup.vue?vue&type=script&lang=js&"
export * from "./ModalCreateGroup.vue?vue&type=script&lang=js&"
import style0 from "./ModalCreateGroup.vue?vue&type=style&index=0&id=528f72de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "528f72de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChatAvatar: require('/home/ubuntu/coherent-frontend/components/Chat/ChatAvatar.vue').default,Clark: require('/home/ubuntu/coherent-frontend/components/Clark.vue').default})
