export default {
  state: () => {
    return {
      sidebar: false,
      clients: [],
      users:[],
      createNote: {
        sessionType: localStorage.getItem("sessionType")
          ? JSON.parse(localStorage.getItem("sessionType"))
          : null,
      },
      note: null,
      surveyLoading: null,
      activeChat: null,
      lastChat: [],
      groupChats: [],
      fullActivity: null,
      clientFilter: {
        name: "",
        session_date: "desc",
      },
      formFilter: {
        name: "asc",
        date: "",
      },
      noteFilter: {
        date: "asc",
      },
      collaboratorsFilter: {
        name: "asc",
      },
      usersFilter: {
        name: "",
      }
    }
  },
  mutations: {
    SET_SIDEBAR(state, payload) {
      state.sidebar = payload
    },
    SET_CLIENTS(state, payload) {
      state.clients = payload
    },
    SET_SESSION_TYPE(state, payload) {
      state.createNote.sessionType = payload
      localStorage.setItem("sessionType", JSON.stringify(payload))
    },
    SET_NOTE(state, payload) {
      state.note = payload
    },
    SET_NOTE_FILTER(state, { field, payload }) {
      state.noteFilter[field] = payload
    },
    SET_COLLABRATORS(state, payload) {
      state.collaborators = payload
    },
    SET_COLLABRATORS_FILTER(state, { field, payload }) {
      state.collaboratorsFilter[field] = payload
    },
    SURVEY_LOADING(state, payload) {
      state.surveyLoading = payload
    },
    SET_CHAT_ITEM(state, payload) {
      if (payload) {
        state.activeChat = { ...payload }
      }
    },
    SET_ACTIVITY_FULL(state, payload) {
      state.fullActivity = payload
    },
    SET_LAST_CHAT(state, payload) {
      const temp = [...state.lastChat]
      const idx = temp.findIndex((item) => item === Number(payload))
      if (idx !== -1) {
        temp.splice(idx, 1)
      }
      temp.unshift(Number(payload))

      state.lastChat = temp
    },
    SET_CLIENT_FILTER(state, { field, payload }) {
      state.clientFilter[field] = payload
    },
    SET_GROUP_CHATS(state, payload) {
      state.groupChats = payload
    },
    SET_FORM_FILTER(state, { field, payload }) {
      state.formFilter[field] = payload
    },
    SET_FORMS(state, payload) {
      state.clients = payload
    },
    SET_USERS(state, payload) {
      state.users = payload
    },
    SET_USERS_FILTER(state, { field, payload }) {
      state.usersFilter[field] = payload
    },
  },
  actions: {
    async fetchGroupChats({ commit }) {
      const { data } = await this.app.$api.GROUP_CHATS.get()
      const parsed = data.map((x) => {
        return {
          type: "group",
          id: x.id,
          name: x.name,
          users: x.users,
          last_message_datetime: x.last_message_datetime,
        }
      })
      commit("SET_GROUP_CHATS", parsed)
    },
  },
  getters: {
    sidebar(state) {
      return state.sidebar
    },
    activeChat(state) {
      return state.activeChat
    },
    lastChat(state) {
      return state.lastChat
    },
    groupChats(state) {
      return state.groupChats
    },
    clientFilter(state) {
      return state.clientFilter
    },
    formFilter(state) {
      return state.formFilter
    },
    noteFilter(state) {
      return state.noteFilter
    },
    collaboratorsFilter(state) {
      return state.collaboratorsFilter
    },
    usersFilter(state) {
      return state.usersFilter
    },
  },
}
