import jsCookie from "js-cookie"

export default async function ({ redirect, store, route }) {
  const data = store.getters["auth/getData"]
  const token = await (data.token ?? jsCookie.get("token"))
  const tokenExpires = await (data.expires ?? jsCookie.get("token_expires"))

  if (!token || !tokenExpires) {
    jsCookie.set("redirect", route.fullPath)
    store.dispatch("auth/logout", false)

    return redirect("/login")
  } else if (!store.getters["auth/isAuthenticated"]) {
    await store.dispatch("auth/setData", {
      token,
      expires: tokenExpires,
    })
  }
}
