
// data
import menuList from "~/jsons/menu.json"
import socialMedia from "~/jsons/socialMedia.json"

export default {
  name: "AppFooter",
  data() {
    const filtered = menuList.filter(
      (x) => x.location === "footer" || x.location === "both"
    )
    return {
      socialMedia,
      footerMenu: filtered,
      bottomLineMenu: [
        {
          title: "Terms of Use",
          to: "/policies/termsofuse",
        },
        {
          title: "Acceptable Use",
          to: "/policies/acceptableuse",
        },
        {
          title: "Privacy Policy",
          to: "/policies/privacypolicy",
        },
      ],
      modal: {
        show: false,
      },
    }
  },
  methods: {},
}
