import Vue from "vue"
import VueEcho from "vue-echo-laravel"
import Echo from "laravel-echo"
// eslint-disable-next-line no-unused-vars
import VuePusher from "vue-pusher"

const EchoInstance = new Echo({
  broadcaster: "pusher",
  host: process.env.WEBSOCKET_HOST,
  key: process.env.WEBSOCKET_KEY,
  cluster: process.env.WEBSOCKET_CLUSTER,
  encrypted: true,
})

Vue.use(VueEcho, EchoInstance)
