export default {
  isAuthenticated(state) {
    if (state.data?.token && state.data?.expires) {
      return true
    } else {
      return false
    }
  },
  getData(state) {
    return state.data
  },
  getUser(state) {
    return state.user
  },
  getToken(state) {
    return state.data?.token
  },
}
