
export default {
  name: "ButtonDefaultSecondary",
  props: {
    to: {
      type: String,
      default: "/",
    },
    buttonLabel: {
      type: String,
      default: "ButtonLabel",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prevent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(e) {
      if (!this.prevent) {
        e.preventDefault()
        this.$router.push(this.to)
      }
      this.$emit("click", e)
    },
  },
}
