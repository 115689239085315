
import ButtonDefaultMain from "~/components/Tools/ButtonDefaultMain.vue"
export default {
  name: "AddClient",
  components: {
    ButtonDefaultMain,
  },
  data() {
    return {
      forms: { first_name: "", last_name: "" },
      loading: false,
    }
  },
  methods: {
    handleCloseAddClientModal() {
      setTimeout(function () {
        const element = document.getElementById("addClietn")
        element.classList.add("hidden")
        this.$refs.addclientref.reset()
      }, 200)
    },
    async handleSave() {
      if (
        this.forms.first_name === null ||
        this.forms.first_name === "" ||
        this.forms.last_name === null ||
        this.forms.last_name === ""
      ) {
        this.$toast.error(
          `<img src="/warning.png" class="w-6 h-6">Please enter client's name!`
        )
        return false
      }
      this.loading = true
      const modelpool = new FormData()
      modelpool.append("first_name", this.forms.first_name)
      modelpool.append("last_name", this.forms.last_name)
      await this.$api.ADDCLIENT.postModel(modelpool)
        .then((res) => {
          const data = res.data
          this.$toast.success("WOOHOO! Client profile created.")
          localStorage.setItem(
            "storeNewClientName",
            data.first_name + " " + data.last_name
          )
          localStorage.setItem("storeNewClientId", data.id)
          this.handleCloseAddClientModal()
          setTimeout(() => {
            if (this.$router.history.current.params.clientid) {
              window.location.assign("/notes/create/" + data.id)
            } else {
              window.location.reload()
            }
          }, 500)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
