export default {
  setData(state, data) {
    state.data = data
  },
  setUser(state, data) {
    state.user = data
  },
  clearData(state) {
    state.data = {
      token: null,
      /* refreshToken: null, */
      expires: null,
    }
    state.user = {}
  },
}
