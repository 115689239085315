
// lib
import { mapGetters, mapMutations } from "vuex"

// jsons
import menuListJSON from "~/jsons/menu.json"

export default {
  name: "AppSidebar",
  data() {
    return {
      menuList: [],
    }
  },
  computed: {
    ...mapGetters(["sidebar"]),
    ...mapGetters("auth", ["isAuthenticated", "getUser"]),
    user() {
      return this.getUser
    },
  },
  watch: {
    "$route.path"() {
      this.SET_SIDEBAR(false)
    },
  },
  mounted() {
    const user = this.user
    let menuList = []

    if (user && user.is_admin === 1) {
      menuList = menuListJSON.filter(
        (x) => x.location === "header" || x.location === "both"
      )
    } else {
      menuList = menuListJSON.filter(
        (x) =>
          (x.location === "header" || x.location === "both") &&
          x.role !== "admin"
      )
    }

    this.menuList = menuList
  },
  methods: {
    ...mapMutations(["SET_SIDEBAR"]),
    handleSidebar() {
      this.SET_SIDEBAR(false)
    },
  },
}
